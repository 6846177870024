<template>
  <div class="content content--home">
    <nav v-if="this.parkInfo" class="menu">
      <div v-if="yourstaynotices.length > 0" class="notices">
        <a href="#" class="btn">
          Park Announcement
          <svg class="icon">
            <use xlink:href="@/assets/icons.svg#entertainment"></use>
          </svg>
        </a>
        <div
          class="yourstay-notice"
          v-for="notice in yourstaynotices"
          :key="notice.id"
        >
          <div class="notice">
            <p>
              <strong>{{ notice.title }}</strong>
            </p>
            <div class="message" v-html="notice.message"></div>
          </div>
        </div>
      </div>
      <router-link class="btn" :to="{ name: 'ParkEvents' }">
        What's on in the park
        <svg class="icon">
          <use xlink:href="@/assets/icons.svg#entertainment"></use>
        </svg>
      </router-link>
      <router-link class="btn" :to="{ name: 'ParkAttractions' }">
        Local attractions
        <svg class="icon">
          <use xlink:href="@/assets/icons.svg#cheers"></use>
        </svg>
      </router-link>
      <router-link class="btn" :to="{ name: 'ParkFeatures' }">
        Park features
        <svg class="icon">
          <use xlink:href="@/assets/icons.svg#swimming"></use>
        </svg>
      </router-link>
      <a v-if="mapUrl" class="btn" :href="mapUrl" target="_blank">
        Park map
        <svg class="icon">
          <use xlink:href="@/assets/icons.svg#map"></use>
        </svg>
      </a>
      <router-link class="btn" :to="{ name: 'ParkContact' }">
        Contact
        <svg class="icon">
          <use xlink:href="@/assets/icons.svg#phone"></use>
        </svg>
      </router-link>
      <a class="btn btn--secondary" :href="parkLink" target="_blank"
        >Make a booking</a
      >
    </nav>
  </div>
</template>

<script>
import constants from "../../constants";

export default {
  name: "ParkLanding",
  props: ["parkInfo"],
  components: {},
  data() {
    return {
      constants: constants,
      parkLink:
        typeof this.parkInfo?.park?.parkUrl === "undefined"
          ? constants.kenticoBaseUrl
          : constants.kenticoBaseUrl + this.parkInfo?.park?.parkUrl,
      yourstaynotices: this.$store.state.parkInfo.yourstayparknotices
        .filter((notice) => {
          // Set today to start of day
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          // Create date objects for start and end dates
          const startDate = new Date(notice.startDate);
          startDate.setHours(0, 0, 0, 0);

          const endDate = new Date(notice.endDate);
          endDate.setHours(23, 59, 59, 999);
          if(window.location.origin.includes('test') || window.location.origin.includes('localhost')) {
            console.log("Notice:", notice.title);
            console.log("Today:", today.toISOString());
            console.log("Start:", startDate.toISOString());
            console.log("End:", endDate.toISOString());
            console.log("Is Active:", notice.active);
            console.log("Start <= Today:", startDate <= today);
            console.log("End >= Today:", endDate >= today);
            console.log("-------------------");
          }

          // Check if notice is valid for today
          return (
            startDate <= today && // Started today or earlier
            endDate >= today && // Ends today or later
            notice.active // Is active
          );
        })
        .sort((a, b) => {
          // Sort by end date first, then by start date if end dates are equal
          const endDateA = new Date(a.endDate);
          const endDateB = new Date(b.endDate);

          if (endDateA.getTime() === endDateB.getTime()) {
            // If end dates are equal, sort by start date
            const startDateA = new Date(a.startDate);
            const startDateB = new Date(b.startDate);
            return startDateA - startDateB;
          }
          return endDateA - endDateB;
        })
        .slice(0, 3), // Get top 3 notices
    };
  },
  mounted() {
    document.body.classList.add("page--" + this.$route.name.toLowerCase());
  },
  unmounted() {
    document.body.classList = "";
  },
  computed: {
    mapUrl() {
      // Check to make sure a park map is present
      let parkMap = false;
      const parkDownloads = this.parkInfo.downloads;
      if (parkDownloads.length >= 1) {
        // Try find the right park map to display
        parkDownloads.forEach(function (download) {
          if (download.displayName.includes("Map") && download.url != "") {
            parkMap = download;
          }
        });

        if (parkMap) {
          return constants.CDNBaseUrl + parkMap.url.substring(1);
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
